@primary-color: #d15d36;

.swiper-pagination {
}

.swiper-no-swiping {
  max-width: 1100px;
}

.swiper-pagination-bullet {
  border-radius: 0 !important;
  --swiper-pagination-bullet-width: 20px;
  --swiper-pagination-bullet-height: 20px;
}

.swiper-pagination-bullet-active {
  --swiper-pagination-color: @primary-color;
}

.ant-input-affix-wrapper > input.ant-input:hover {
  box-shadow: none;
}

// TODO: remove is style
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 23px !important;
  border: 0;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 23px !important;
  border: 0 !important;
}

.ant-form-item-control-input
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  border: 0 !important;
  transition: none !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 0 !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-hovered {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14) !important;
  border-color: #dcdcdc !important;
  transition: none !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  // line-height: 22px !important;
}

.card-element {
  padding: 20px;
  border: 1px solid #dcdcdc;
  background-color: #f1f2f7;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0px 2px 8px rgb(0 0 0 / 25%);
    outline: 0;
    border-color: #dcdcdc;
    transition: all 0.3s;
  }
}

.ant-divider {
  border-color: #cccccc;

  &.ant-divider-horizontal.ant-divider-with-text {
    border-color: #cccccc;
  }
}

h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.035em;
  text-transform: capitalize;
  color: #000000;

  span {
    display: block;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.19em;
    text-transform: uppercase;

    color: #000000;
  }
}

.ant-form-item {
  flex-direction: column;
  align-items: flex-start;

  .ant-form-item-control {
    width: 100%;
    max-width: 100%;
  }

  .ant-form-item-label {
    width: 100%;
    max-width: 100%;
    text-align: left;

    label {
      font-weight: 500;
      font-size: 14px;
      line-height: 40px;
      height: auto;
      display: inline-block;
      color: rgba(0, 0, 0, 1);

      & > div {
        display: initial;
      }

      span {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 40px;
        display: block;
        text-transform: initial;
        white-space: break-spaces;
        color: rgba(0, 0, 0, 0.8);

        &:last-of-type {
          font-weight: 300;
          font-size: 14px;
          line-height: 30px;
        }
      }
    }
  }

  label {
    &::before {
      display: none;
    }

    &::after {
      display: none;
    }
  }

  input {
    background: #f1f2f7;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 10px 24px;
    color: var(--placeholder);
    line-height: 40px;
  }

  .input-subfix {
    background: #f1f2f7;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    color: var(--placeholder);
    width: 100%;

    display: flex;
    align-items: center;

    input {
      flex: 1;
      background: none;
      border: none;
      border-radius: 0;
    }

    span {
      border: none;
      background: none;
      border-left: 0.5px solid #000000;
      padding: 10px 20px;
    }
  }

  .ant-form-item-control-input-content {
    // display: inline-flex;
    // align-items: center;
    input {
      // width: calc(100% - 40px) !important;
      // margin-right: 10px;
    }

    span[role="img"] {
      display: inline-flex;
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .ant-select {
    border: 1px solid #dcdcdc !important;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 60px !important;
    border: 1px solid #dcdcdc !important;
  }

  .antd-country-phone-input {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      height: 23px !important;
      border: none !important;

      .ant-select-selection-search-input {
        height: 0 !important;
      }
    }

    .ant-select {
      border: 0 !important;
    }

    input {
      line-height: 1;
    }
  }

  .ant-select-selector {
    cursor: pointer !important;
    line-height: 40px;
    // padding: 10px 24px !important;
    color: var(--placeholder);
    background: #f1f2f7 !important;
    align-items: center;

    &:focus {
      outline: none;
    }
  }

  .ant-select-selection-item {
  }

  .ant-radio-wrapper {
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: rgba(0, 0, 0, 1);
  }
}

.ant-tabs-nav-list {
  border-radius: 8px;

  .ant-tabs-tab {
    background: #f1f2f7;
    border: 1px solid #dcdcdc;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 40px;
    padding-left: 40px;
    padding-right: 40px;

    &:first-of-type {
      // border-radius: 8px 0px 0px 8px;
    }

    &:last-of-type {
      // border-radius: 0px 8px 8px 0px;
    }

    & + .ant-tabs-tab {
      margin: 0;
    }

    &.ant-tabs-tab-active {
      background: #ffffff;
      color: #d15d36;
    }
  }

  .ant-tabs-ink-bar {
    background: none;
  }
}

.ant-modal-close {
  width: 24px;
  height: 24px;
  top: 60px;
  right: 60px;
}

.my-6 {
  height: 225px;
  max-height: 225px;
  overflow-y: auto;
  overflow-x: clip;
  &.centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

// custom switch
.ant-switch {
  background-color: #d2d2d2;
}

.ant-switch-checked {
  background-color: @primary-color;
}

.ant-select {
  .ant-select-clear {
    display: none;
  }
}

.back-button {
  color: rgb(191, 189, 190);
  font-weight: bold;
  text-transform: uppercase;
}

.sendmessage-textarea-wrap {
  position: relative;

  .hint {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 10px;
    display: inline-flex;
    // align-items: center;

    button {
      display: inline-flex;
      background: #f1f2f7;
      opacity: 0.5;
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      cursor: default;
      align-items: center;

      & + button {
        margin-left: 10px;
      }
    }
  }

  textarea {
    padding-right: 150px;
  }

  .textarea-actions {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    right: 20px;
    display: inline-flex;
    align-items: center;

    svg {
      cursor: pointer;

      & + svg {
        margin-left: 10px;
      }
    }
  }

  .ant-form-item-has-error {
    & + .textarea-actions {
      bottom: 40px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .ant-modal-close {
    width: 16px;
    height: 16px;
    top: 16px;
    right: 16px;

    .ant-modal-close-x {
      width: 16px;
      height: 16px;
    }
  }
}
