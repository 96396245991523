.add-new-form-page {
  textarea.ant-input {
    min-height: 212px;
  }
}

.vcard-modal {
  .ant-modal-body {
    max-height: 1000px;
    min-height: 200px;
    height: 90vh;
    overflow: auto;

    .vcard {
      border: none;
    }
  }
}

.input-subfix {
  .prefix-input-domain {
    border-radius: 4px 0 0 4px;
  }
  .suffix-domain {
    // height: 62px;
    background: #f1f2f7;
    border: 1px solid #dcdcdc;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.custom{
  // border: 1px solid #dcdcdc;
  // padding-left: 20px;
  // padding-right: 20px;
  background: #f1f2f7;
  width: 100%;
  height: 100%;
}


.cnameCustom .ant-input-group-addon {
    background: #f1f2f7;
}

