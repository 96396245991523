.contact-import-form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  padding: 5px;
  height: 40px !important;
  border: 0;
  background-color: rgb(245 245 245) !important;
}

.contact-import-form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 40px !important;
}

.contact-import-form .ant-input-affix-wrapper {
  height: 50px !important;
}

.contact-import-form .next-btn {
  background-color: #d15d36 !important;
  color: white;
}
