.avatar-wrap {
  position: relative;
  display: inline-flex;

  .ant-avatar {
    align-items: center;
    justify-content: center;
    display: flex;
  }

  .icon-camera {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    // padding-top: 8px;
    // padding-bottom: 16px;
    border-radius: 50%;
    width: 49px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .avatar-input {
    display: none !important;
  }

  svg {
    // height: 16px;
  }
}
