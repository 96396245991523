.key-response-modal {
  .sendmessage-textarea-wrap {
    padding: 0 16px;
    background-color: #f1f2f7;
    background-image: none;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
