.automation-action {
  width: 230px;
  height: 223px;
  background: rgba(220, 220, 220, 0.5);
  padding: 24px;

  .automation-action-label {
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    color: #000000;
    display: block;
  }

  .automation-action-description {
    font-size: 12px;
    color: #000000;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  button {
    border: none;
    background: none;
    box-shadow: none;
    font-weight: normal;
  }
}

@media only screen and (max-width: 768px) {
  .automation-action {
    padding: 8px;
    height: auto;

    .automation-action-label {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
