.automation-trigger-modal.first-contact-message-modal {
  .delay-wrap {
    label {
      font-weight: 600;
      font-size: 12px;
      line-height: 40px;
      letter-spacing: 0.075em;
      text-transform: uppercase;
      margin-top: 16px;
    }

    .ant-input {
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      height: 50px;
      width: 100%;
      padding: 13px;
    }
  }
  .react-datepicker-wrapper {
    input {
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      height: 50px;
      width: 100%;
      padding: 13px;
    }
  }

  .react-datepicker__time-container {
    min-width: 120px;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box {
    width: 100%;
  }

  .react-datepicker__time-list-item {
    display: inline-flex;
    align-items: center;
  }

  .sendmessage-textarea-wrap {
    padding: 0 16px;
    background-color: #f1f2f7;
    background-image: none;
    border: 1px solid #dcdcdc;
    border-radius: 2px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
