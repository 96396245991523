.DeleteScheduleModal {
  .ant-modal-body {
    padding-left: 24px;
    padding-right: 24px;
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 36px;
    text-align: center;
  }
}
