.profile-page {
  h1 {
    margin-bottom: 60px;
  }

  .form-profile {
  }

  .ant-divider {
    margin: 60px 0;
    border: none;
    border-top: 1px solid #000000;
  }
}
