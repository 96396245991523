.layout {
  min-height: 100vh !important;
  background: #c4c4c4;
 

  .ant-layout-sider {
    z-index: 10;
    flex: 0 0 auto !important;
    max-width: none !important;
    width: auto !important;
  }

  header {
    background: #ffffff;
    border-bottom: 1px solid #dbdbdb;
    height: 100px;
    padding-left: 0;
    padding-right: 70px;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .header-menu {
      position: relative;
      .header-avatar-wrap {
        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;

        .ant-avatar {
          align-items: center;
          justify-content: center;
          display: flex;
        }

        .arrow-down-icon {
          margin-left: 20px;
        }
      }
      .header-menu-content {
        position: absolute;
        top: calc(100% + 10px);
        right: 0;
        background: #ffffff;
        box-shadow: 0px 0px 32px #f1f1f1;
        padding: 10px;
        text-align: left;
        text-transform: uppercase;

        a {
          font-size: 14px;
          color: var(--placeholder);
        }
      }
    }
  }

  aside {
    background: #ffffff;
    border-right: 1px solid #dbdbdb;
    width: 115px;

    .ant-layout-sider-children {
      display: inline-block;
      position: relative;
      margin-top: 99px;
      height: calc(100% - 99px);
      border-top: 1px solid #dbdbdb;
    }

    .collapsed-btn {
      position: absolute;
      top: 7px;
      left: calc(100% - 16px);
      width: 10px;
      align-items: center;
      display: inline-flex;
      justify-content: center;
      overflow: hidden;
      background: #ffffff;
      border-radius: 50%;
      z-index: 10;
      overflow: hidden;

      span {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 50%;
          background: #ffffff;
        }
      }
      svg {
        font-size: 16px;
      }
    }

    ul {
      height: calc(100vh - 143px);
      min-width: 255px;
      transition: all 0.2s;
      border: none;
      border-left: 1px solid #ecebeb;

      li {
        margin-top: 50px !important;
        display: flex;
        justify-content: flex-start;
        padding-left: 40px;
        position: relative;

        .ant-menu-submenu-title {
          padding-left: 0 !important;
        }

        .ant-menu-title-content {
          align-items: center;
          display: inline-flex;
        }

        a {
          color: var(--placeholder);
          white-space: nowrap;
          flex-wrap: nowrap;
          display: inline-flex;
          align-items: center;

          &.active {
            color: #d15d36;
            &::after {
              content: "";
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              border-right: 2px solid #000000;
            }
          }
        }

        svg {
          // margin-right: 16px;

          & + span {
            margin-left: 16px;
          }
        }
      }

      .ant-menu-item {
        padding-left: 40px !important;
        height: 64px;
      }

      &.ant-menu-inline-collapsed {
        &:not(.menu-hover) {
          min-width: 115px;
          .menu-item-label {
            display: none;
            text-transform: capitalize;
          }
        }
      }
    }
  }

  .layout-content {
    z-index: 1;
  }

  main {
    position: relative;
    padding: 30px;
  }
}

.ant-menu-inline-collapsed-tooltip {
  display: none !important;
}

.layout-mobile {
  display: none;
}

.layout-desktop {
  display: block;
}

@media only screen and (max-width: 1520px) {
  .layout {
    main {
      padding: 16px;
    }
  }
}

@media only screen and (max-width: 990px) {
  .layout-desktop {
    display: none;
  }

  .layout-mobile {
    display: block;

    .ant-layout-header {
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
    }

    .ant-layout-content {
      margin-top: 50px;
    }

    .layout-mobile-mobile {
      width: 50px;
      border-right: 1px solid #dbdbdb;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .menu-hamburger {
      position: relative;
      width: 50px;
      height: 50px;
      .menu-hamburger-line {
        transition-duration: 0.5s;
        position: absolute;
        height: 2px;
        width: 16px;
        top: 22px;
        left: 17px;
        background-color: rgb(0, 0, 0);

        &::before {
          transition-duration: 0.5s;
          position: absolute;
          width: 16px;
          height: 2px;
          background-color: rgb(0, 0, 0);
          content: "";
          top: -5px;
          left: 0;
        }

        &::after {
          transition-duration: 0.5s;
          position: absolute;
          width: 16px;
          height: 2px;
          background-color: rgb(0, 0, 0);
          content: "";
          top: 5px;
          left: 0;
        }
      }
    }

    .menu-mobile-popup {
      // background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;

      .menu-mobile-popup-backdrop {
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
      .menu-mobile-popup-content {
        background-color: #ffffff;
        height: 100vh;
        width: 293px;
        overflow-y: auto;

        ul {
          height: 100%;
        }
      }

      .ant-menu-title-content {
        svg {
          height: 20px;
        }

        a {
          display: flex;
          align-items: center;
        }
      }
      .menu-mobile-update {
        svg {
          height: 18px;
        }
      }
    }
  }

  .layout {
    header {
      margin: 0;
      height: 50px;
      text-align: center;
    }
  }
}
