.DropdownReactSelect {
  .formatGroupLabel {
    font-size: 18px;
    font-weight: bold;
    color: #000000;
  }

  .custom-option {
    padding-left: 24px;
  }

  .DropdownReactSelect-Placeholder {
    height: 60px;
    display: flex;
    align-items: center;
  }

  .DropdownReactSelect-SingleValue {
    height: 60px;
    display: flex;
    align-items: center;
  }
}
