.SideBarUpdate {
  width: 350px;
  // max-height: calc(100vh - 70px);
  padding: 16px;
  background: #ffffff;

  .SideBarUpdate-input {
    height: 54px;
    background: rgba(241, 242, 247, 0.5);
    border: 1px solid rgba(186, 186, 186, 0.5);
    border-radius: 8px;

    input {
      border: 0;

      &:hover,
      &:focus,
      .ant-input-focused {
        box-shadow: none;
      }
    }
  }

  .SideBarUpdate-edit-btn {
    background: rgba(241, 242, 247, 0.5);
    border: 1px solid rgba(241, 242, 247, 0.5);
    border-radius: 8px;
    padding: 16px;
    height: 54px;
  }

  .SideBarUpdate-item {
    padding: 13px 30px;
    border-radius: 14px;
    display: block;

    &:hover {
      border: 1px solid #f1f2f7;
      background: rgba(241, 242, 247, 0.5);
    }

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 40px;
    }

    span {
      color: rgba(0, 0, 0, 1);
      span {
        color: rgba(112, 112, 112, 1);
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .SideBarUpdate {
    width: 100%;
  }
}
