.Message-page {
  .ant-col-16 {
    padding-right: 24px !important;
  }
  .ant-layout-content {
    padding: 0;
  }
}

@media only screen and (max-width: 990px) {
  .Message-page {
    .ant-layout-content {
      padding: 0;
    }
  }
}
