.col-drag-handler {
  width: 4%;
  min-width: 30px;
}

.col-keyword {
  width: 20%;
  min-width: 150px;
}

.col-message {
  min-width: 200px;
}

.col-action {
  width: 10%;
  min-width: 148px;
  padding-right: 0 !important;
}
