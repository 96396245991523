:root {
  /* TODO: move to taiwind config */
  --bg-hover: #f1f2f7;

  --primary: #d15d36;
  --placeholder: #707070;
  --gray-text: rgb(150, 150, 150);
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  /* color: #000000; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa; 
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 11rem;
  background: #999; 
}

::-webkit-scrollbar:horizontal {
  height: 6px !important;
}

.ant-popover-inner-content{
  padding: 0px !important;
}