.SideBarMessage {
  .SideBarMessage-message {
    border: none;
    margin: 0;
    padding: 0;
    input {
      color: rgb(162, 162, 162) !important;
      background: transparent;
      border: 0;
      pointer-events: none;
      &:hover,
      &:focus,
      &:disabled,
      .ant-input-focused {
        cursor: default;
        box-shadow: none;
      }
    }
  }

  .SideBarMessage-edit-btn {
    background: rgba(241, 242, 247, 0.5);
    border: 1px solid rgba(241, 242, 247, 0.5);
    border-radius: 8px;
    padding: 16px;
    height: 54px;
  }

  .SideBarMessage-item {
    margin-bottom: 5px;
    padding: 13px 10px;
    border-radius: 14px;
    display: block;
    border: 1px solid #f1f2f7;
    background: #ffffff;

    h3 {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }

    span {
      color: rgba(0, 0, 0, 1);
      span {
        color: rgba(112, 112, 112, 1);
      }
    }
  }
  .searchbar{
    width: 90%;
    margin-left: 15px;
    margin-bottom: 10px;
    padding: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    // background-color: red;
    &:focus{
      border: none;
      outline: none;
      border-bottom: 1px solid  rgba(0, 0, 0, 0.5);
    }
  }
}

#scrollableDiv{
  overflow-y: hidden;
}

#scrollableDiv:hover{
  overflow-y: auto;
  transition: 2s ease-in-out;
}

.SideBarMessage-list {
    height: 82.5vh !important;
}

@media only screen and (min-width: 767px) {
  .SideBarMessage {
    min-height: 100%;
  }
}

@media (max-height: 800px) {
  .SideBarMessage-list {
    height: 80vh !important;
  }
}

@media only screen and (max-width: 768px) {
  .SideBarMessage {
    flex: 1;
  }
}
