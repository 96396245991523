.NewSegmentModal {
  // we will show custom close in this time
  .ant-select {
    .ant-select-clear {
      display: none;
    }
  }

  .segment-filter-item {
    display: flex;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px;
    border-radius: 4px;
    margin: 10px 0px;
    padding: 8px;
    position: relative;

    .dynamic-delete-button {
      position: absolute;
      top: 50%;
      right: -10px;
      transform: translateY(-50%);
      background: #d15d36;
      border-radius: 50%;
      height: 22px;
      width: 22px;
      display: none;
      align-items: center;
      justify-content: center;

      svg {
        color: #ffffff;
      }
    }

    &:hover {
      .dynamic-delete-button {
        display: flex;
      }
    }

    .ant-select-selection-item {
      align-items: center;
      display: flex;
    }

    .ant-select-selector {
      align-items: center;
    }
  }
}
