.signin-page {
  &-content {
    max-width: 603px;
    margin: 0 auto;
  }

  .or-diveder {
    font-style: italic;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 0.035em;
  }

  .btn-google {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14) !important;
    border-radius: 50px !important;
    min-width: 28px;
    height: 62px;
    padding: 16px 40px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
  }
}
