.input-subfix {
  .prefix-input-domain {
    border-radius: 4px 0 0 4px;
  }
  .suffix-domain {
    height: 62px;
    background: #f1f2f7;
    border: 1px solid #dcdcdc;
    padding-left: 20px;
    padding-right: 20px;
  }
}
