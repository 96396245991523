.w-tabs-list-h {
  max-width: 360px;
  margin: 24px auto 0;
  background: #e3ddd8;
  border-radius: 40px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
}
.w-tabs-item {
  padding: 16px 10px;
  font-size: 14px;
  border-radius: 40px;
  background: none !important;
  color: #000 !important;
  font-weight: 600;
  border: none !important;
  flex: 1 1 50%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &.active {
    background: #fff !important;
  }
}
