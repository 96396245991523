.add-custom-field-modal {
  .ant-modal-content {
    min-height: 350px;
  }
}

.custom-field {
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 3px;
  height: 60px;

  & + & {
    margin-top: 6px;
  }

  .custom-field-list & {
    background: #ffffff;
    border: 1px solid #f1f2f7;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    span {
      line-height: 1;
      font-size: 16px;

      .custom-field-type {
        color: var(--placeholder);
        font-size: 14px;
        margin-bottom: 4px;
        display: block;
      }
    }

    button {
      border: 1px solid #d15d36;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    }
  }

  span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 0.155em;
    text-transform: uppercase;
  }

  svg {
    width: 24px;
    margin-right: 15px;
  }

  button {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 40px;
    letter-spacing: 0.03em;
    color: #d15d36;
    background: none;
    border: none;
  }
}

.select-custom-field-btn {
  // background: #ffffff;
  border: 1px solid #f1f2f7;
  // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.custom-field-dropdown-item {
}
