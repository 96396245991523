.SendTestMessage {
  .ant-modal-body {
    padding-left: 120px;
    padding-right: 120px;
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 36px;
    text-align: center;
  }

  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .SendTestMessage-merge-field {
    span {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      padding: 3px;
      background: #f2f2f2;
      color: #969696;
      letter-spacing: 0.01em;
      line-height: 1;
      display: inline-block;
      margin-right: 1px;
      border-radius: 3px;
    }

    input {
      padding-left: 100px;
      height: 66px;
      background: transparent;
    }
  }
}
