.quill {
  height: 244px;
  width: 100%;

  .ql-toolbar {
    .ql-picker-label {
      font-family: "Montserrat", sans-serif;
    }

    .ql-picker-options {
      span {
        font-family: "Montserrat", sans-serif;
      }
    }
  }

  .ql-container {
    height: calc(100% - 50px);

    .ql-editor {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      color: black;
    }

    .ql-editer.ql-blank::before {
      font-style: inherit;
      font-size: 14px;
      font-family: "Montserrat";
    }
  }
}
