.updates-detail-page {
  .ant-layout-content {
    padding: 0 0 0 16px;
  }

  main {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  .updates-detail-page-content {
    p {
      font-weight: 700;
      font-size: 24px;
      line-height: 40px;
      margin-top: 50px;
      margin-bottom: 32px;
    }
  }

  h2 {
    span {
      color: var(--gray-text);
    }
  }

  .upcomming-block {
    position: relative;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(242, 242, 242);
    box-sizing: border-box;
    box-shadow: rgb(0 0 0 / 5%) 0px 2px 4px;
    border-radius: 4px;
    margin: 15px 0px;
    height: auto;
  }

  .upcomming-actions {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media only screen and (max-width: 768px) {
  .updates-detail-page {
    .ant-layout-content {
      padding: 0;
    }
  }
}
