.SubscriberInfor-tag-list {
  .isShowTag {
    flex-direction: column;

    & > p {
      margin-bottom: 8px;
    }
  }
}

.SubscriberInfor-tag-select.ant-select-single:not(.ant-select-customize-input) {
  .ant-select-selector {
    height: 100% !important;
  }
}
