.uploadfile-modal {
  width: 100%;
  max-width: 800px;

  form {
    background: rgba(241, 242, 247, 0.5);
    border: 1px solid #dcdcdc;
    border-style: dashed;
    border-radius: 8px;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .drag-drop-label {
    height: 400px;
  }

  svg {
    width: 90px;
  }

  .upload-file-input {
    display: none !important;
  }
}
