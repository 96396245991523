.ant-modal {
  &.reset-modal {
    max-width: 840px;
    width: 100% !important;

    form {
      max-width: 603px;
      margin: 0 auto;
    }
  }

  &.small-modal {
    max-width: 840px;
    width: 100% !important;

    .small-body-modal {
      max-width: 603px;
      margin: 0 auto;
    }
  }
}
