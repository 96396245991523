.signup-confirmation-page {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;

  .success-icon {
    font-size: 250px;
    color: green;
    margin-bottom: 50px;
  }

  svg {
    height: 50px;
    width: auto;
    margin: 0 auto;
  }

  .signup-confirmation-box {
    max-width: 100%;
    width: 500px;
    border: 1px solid #f1f1f1;
    border-radius: 4px;
    padding: 16px;
  }

  .note {
    color: #acacac;
  }
}
