.ConfirmScheduleModal {
  .ant-modal-body {
    padding-left: 120px;
    padding-right: 120px;
  }

  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    margin-bottom: 36px;
    text-align: center;
  }

  .ConfirmScheduleModal-preview {
    background: rgba(220, 220, 220, 0.5);
    border: 1px solid #dcdcdc;
    border-radius: 8px;
    padding: 32px 150px;
  }

  .phone-image-content-message {
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;
    padding: 16px 32px;
    max-width: 100%;

    &::after {
      bottom: -8px;
      border-right: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-top: 10px solid transparent;
      border-left: 10px solid #dcdcdc;
    }
  }
}
