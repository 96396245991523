.messageDetail-page {
  .ant-layout-content {
    padding: 0;
  }

  .messageDetail-page-send-message {
    margin: 0 8px;
    padding: 8px;
    background: #ffffff;
  }

  .messageDetail-page-attachment {
    padding-right: 80px;
  }

  .messageDetail-EditableText {
    height: 120px;
  }
}

.messageDetail-body {
  max-height: calc(100vh - 160px);
}

/////////////////////////////////////////


.layout-mobile-info {
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menu-hamburger-info {
  position: relative;
  width: 50px;
  height: 50px;
  
  .menu-hamburger-line-info {
    transition-duration: 0.5s;
    position: absolute;
    height: 2px;
    width: 16px;
    top: 22px;
    left: 17px;
    background-color: rgb(0, 0, 0);
    

    &::before {
      transition-duration: 0.5s;
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: rgb(0, 0, 0);
      content: "";
      top: -5px;
      left: 0;
    }

    &::after {
      transition-duration: 0.5s;
      position: absolute;
      width: 16px;
      height: 2px;
      background-color: rgb(0, 0, 0);
      content: "";
      top: 5px;
      left: 0;
    }
  }
}

.info-mobile-popup {
  // background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: row-reverse;

  .info-mobile-popup-backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
  }
  .info-mobile-popup-content {
    background-color: #ffffff;
    height: 100vh;
    // width: 293px;
    overflow-y: auto;
  }
}