.EditableText {
  position: relative;
  width: 100%;
  height: 200px;
  margin-top: 10px;

  & > div {
    // height: 200px;
  }

  .hint {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 10px;

    button {
      display: inline-flex;
      background: #f1f2f7;
      opacity: 0.5;
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      align-items: center;

      & + button {
        margin-left: 10px;
      }
    }
  }

  .EditableText-body {
    position: relative;
    // padding-right: 170px;
    height: 141px !important;
  }

  .EditableText-placeholder {
    position: absolute;
    top: 0;
    color: var(--placeholder);
  }

  .EditableText-content {
    outline: none;
    height: 100%;
    overflow-y: auto;
    width: 100%;
    max-width: 100%;
    word-break: break-word;

    span {
      background: rgb(241, 241, 241);
      color: rgb(169, 169, 169);
      text-transform: lowercase;
      padding: 3px 7px;
      letter-spacing: 0.01em;
      line-height: 1;
      display: inline-block;
      border-radius: 3px;
    }

    .mergeField {
      padding: 2px;
      background-color: var(--placeholder);
      color: white;
      border-radius: 5px;
    }
  }

  .EditableText-dropdown {
    position: absolute;
    top: 50px;
    // left: 50%;
    // transform: translateX(-50%);
    left: 24px;
    right: 24px;
    width: 450px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
    z-index: 10;

    &.EditableText-dropdown-top {
      bottom: 100%;
    }

    .EditableText-dropdown-item {
      padding: 16px;
      background: #ffffff;
      &:hover {
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.06);
        outline: 0;
        background: var(--bg-hover);
      }

      & {
        border-top: 1px solid #dcdcdc;
      }
      &.active {
        background: var(--bg-hover);
      }
    }
  }

  #shadowEditableRef {
    display: none;
  }
}

// TODO: move to common
.custom-textarea-wrap {
  position: relative;

  .hint {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 10px;
    display: inline-flex;

    button {
      display: inline-flex;
      background: #f1f2f7;
      opacity: 0.5;
      border: 1px solid #dcdcdc;
      border-radius: 8px;
      align-items: center;

      & + button {
        margin-left: 10px;
      }
    }
  }

  textarea {
    padding-right: 150px;
  }

  .textarea-actions {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    right: 20px;
    display: inline-flex;
    align-items: center;

    svg {
      cursor: pointer;

      & + svg {
        margin-left: 10px;
      }
    }

    & > div {
      position: absolute;
      bottom: 0;
    }
  }

  .ant-form-item-has-error {
    & + .textarea-actions {
      bottom: 40px;
    }
  }
}

.editable-textarea::-webkit-scrollbar-thumb:hover {
  cursor: pointer !important;
} 
